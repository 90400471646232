
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Any troubles with <font color="#C64A74"><b>Earnity</b></font>?
        </p>
        <p>
          Contact us at: <b><a href = "mailto: support@earnity.pl">support@earnity.pl</a></b>!
        </p>
      </header>
    </div>
  );
}

export default App;
